
<template>
<div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-white">
          <b-form-group label="Filter by Status" class="mb-2" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-2"
              v-model="defaultStatus"
              :aria-describedby="ariaDescribedby"
              name="radio-default-status"
            >
              <b-form-radio  value="all">All</b-form-radio>
              <b-form-radio  value="draft">Draft</b-form-radio>
              <b-form-radio  value="active">Active</b-form-radio>
              <b-form-radio  value="progress">Progress</b-form-radio>
              <b-form-radio  value="completed">Completed</b-form-radio>
              <b-form-radio  value="refunded">Refunded</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-8">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                </label>
                <b-dropdown left variant="light">
                  <template slot="button-content">
                    <i class="mdi mdi-filter-variant"></i>
                  </template>
                  <b-dropdown-item-button @click="selectAllRows()">
                    <i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'Inverse selection' : 'Select All' }}
                    </b-dropdown-item-button>
                  <b-dropdown-item-button @click="updateStatus()">
                    <i class="mdi mdi-tooltip-edit"></i> Update Status
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="marketingMail()">
                    <i class="mdi mdi-email-edit-outline"></i> Send e-Mail
                  </b-dropdown-item-button>
                  <b-dropdown-item-button >
                    <i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="">
                <label class="d-block">
                  <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
                ref="selectableTable" :items="orders" :fields="columns" responsive="sm"  >
                <template v-slot:cell(check)="data">
                  <div class="custom-control custom-checkbox text-center">
                    <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                    <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                  </div>
                </template>
                <template v-slot:cell(number)="data">
                  <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                    <span @click="navigateTo(data.item.id)" class="text-dark font-sm">#{{ data.item.number }}</span>
                  </h5>
                </template>
                <template v-slot:cell(buyer)="data">
                  <h5 class="m-0 d-inline-block cursor-pointer align-middle" v-if="data.item.buyer">
                    <span @click="navigateTo(data.item.id)" class="text-dark font-sm">{{ data.item.buyer.first_name }} {{ data.item.buyer.last_name }}</span>
                  </h5>
                </template>
                <template v-slot:cell(product)="data">
                  <h5 @click="navigateTo(data.item.id)" style="min-width:6rem;" class="m-0 text-dark font-sm d-inline-block cursor-pointer align-middle">
                    {{ data.item.type == "TASK" ? data.item.task.name : data.item.product.name }}
                  </h5>
                </template>
                <template v-slot:cell(price)="data">
                  ${{ data.item.amount | money_format }}
                </template>
                <template v-slot:cell(date)="data">
                  <div @click="navigateTo(data.item.id)" style="min-width:6rem;" class="cursor-pointer">
                    <span class="d-block">{{ data.item.created_at | date_time(1) }} </span>
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" @click="deleteItem(data.item.id)" class="action-icon text-danger">
                        <i class="mdi mdi-delete"></i></a>
                    </li>
                  </ul>
                </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="paging_simple_numbers float-right">
                <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal size="md" title="Update Status"
    body-bg-variant="light" body-class="p-3"
      hide-footer v-model="modalUpdateStatus">
    <div>
      <update-status v-if="modalUpdateStatus" :selected="selected" @feedback="initializeAll()" />
    </div>
</b-modal>
<b-modal size="lg" title="Marketing Mail"
    body-bg-variant="white" body-class="p-3"
      hide-footer v-model="modalMarketingMail">
    <div>
      <marketing-mail v-if="modalMarketingMail"
      :selectedUsers="selectedUsers"
      @feedback="initializeAll()" />
    </div>
</b-modal>

</div>

</template>

<script>
import UpdateStatus from "./UpdateStatus.vue";
import MarketingMail from "./MarketingMail.vue";
import IsLoading from '../IsLoading.vue';
import _ from 'lodash';

export default {
  name:"order-list-table",
  components:{
    UpdateStatus,
    MarketingMail,
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      defaultStatus:"all",
      searchQuery:"",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
        {
          key: "check",
          label: "ID"
        },
        {
          key: "number",
          sortable: true
        },
        {
          key: "buyer",
          sortable: true
        },
        {
          key: "product",
          sortable: true
        },
        {
          key: "price",
          sortable: true
        },
        {
          key: "date",
          label: "Date",
          sortable: true
        },
        "action"
      ],
      modalUpdateStatus: false,
      modalMarketingMail: false,
      selectedUsers: []
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus",
    defaultStatus (val) {
      this.fetchOrders()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.orderList.pageData
    },
    orders() {
      return this.pageData.data.filter((order) => this.defaultStatus == "all" 
            || (order.status == this.defaultStatus))
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchOrders(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchOrders(1, val)
      }
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/orders/${id}`});
    },
    refreshQueryStatus(){
      if(this.$route.query.status){
        this.defaultStatus = this.$route.query.status
      }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.orders.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateStatus(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.modalUpdateStatus = true
    },
    async marketingMail(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.selectedUsers = this.orders.filter((ord) => this.selected.includes(ord.id)).map((ord) => {
        return {id: ord.buyer.id, first_name: ord.buyer.first_name, email: ord.buyer.email,}
      })
      this.modalMarketingMail = true
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchOrders()
    }, 2000),
    fetchOrders(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrders", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete an order permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${ itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
                this.$store.commit("orderList/DELETE_ORDER", itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.modalUpdateStatus = false,
      this.modalMarketingMail = false
      this.selectedUsers = []
    }
  },
  mounted() {
    this.refreshQueryStatus()
    if(this.defaultStatus == "all"){this.fetchOrders()}
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
