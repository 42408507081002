
<template>
<div>
  <div class="row">
    <div class="col-12">
      <order-list-table />
    </div>
  </div>
</div>

</template>

<script>

import OrderListTable from "@/components/order/OrderListTable.vue"

export default {
  components: {
    OrderListTable,
  },
}
</script>
